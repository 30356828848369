/* eslint-disable react/no-array-index-key */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
// Components
import SEO from 'components/SEO'
import { Layout, Hero2, Article } from 'components/shared'
import { Button, WhatsappButton } from 'components/elements'
import ParseContent from 'components/shared/ParseContent'

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          title
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        services {
          title
          usp {
            title
            description
          }
          button {
            title
            url
          }
        }

        meta {
          title
          description
          button {
            title
            url
          }
        }
      }

      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`

const BGDiv = styled.div`
  height: 85vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;

  @media (max-width: 991px) {
    height: 100%;
  }
`

const Left = styled.div`
  background: #f1ebda;
`

const Right = styled.div`
  background: #3f4321;
`
const ServicesDiv = styled.div`
  height: 85vh;
  z-index: 10;

  @media (max-width: 991px) {
    height: 100%;
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoastMeta, acf },
  },
}) => {
  return (
    <Layout headerActive="Diensten">
      {yoastMeta && (
        <SEO
          yoast={{ meta: yoastMeta }}
          pathname={path}
          image={acf.banner.image.localFile.childImageSharp.fluid.src}
          article
        />
      )}

      <Hero2 backgroundImg={acf.banner.image} title={acf.banner.title} />
      <WhatsappButton />
      <section className="position-relative">
        <BGDiv>
          <Left className="col-12 col-md-6" />
          <Right className="col-12 col-md-6" />
        </BGDiv>
        <div className="container">
          <div className="row">
            <ServicesDiv className="col-md-12 d-flex flex-wrap">
              {acf.services.map(({ title, usp, button }, index) => (
                <div className="col-12 col-md-6 px-0">
                  <ServiceSection
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    title={title}
                    button={button}
                    usp={usp}
                    left={index % 2 === 0}
                  />
                </div>
              ))}
            </ServicesDiv>
          </div>
          <Article
            title={acf.meta.title}
            description={acf.meta.description}
            button={acf.meta.button}
          />
        </div>
      </section>
    </Layout>
  )
}

export default PageTemplate

const ServiceSectionContainer = styled.div`
  padding: 45px 0 90px 0;
  transition: all 300ms ease;
  z-index: 0;

  @media screen and (max-width: 1060px) {
    padding: 30px 0 60px 0;
  }
`
const ServiceSectionWrapper = styled.div`
  margin-left: ${(props) => (props.left ? 'auto' : '100px')};
  margin-right: ${(props) => (props.left ? '100px' : 'auto')};
  width: 420px;
  transition: all 300ms ease;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1060px) {
    margin-left: ${(props) => (props.left ? 'auto' : '60px')};
    margin-right: ${(props) => (props.left ? '60px' : 'auto')};
    width: 300px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
  }
`
const ServiceSectionTitle = styled.h5`
  color: ${(props) => (props.left ? '#1D2217' : 'white')};
  text-align: center;
  font-size: 25px;
  line-height: 30px;
  padding: 0 8px 18px 8px;
  border-bottom: ${(props) =>
    props.left ? '3px solid #cab996' : '3px solid white'};
  margin-bottom: 60px;
  width: fit-content;
  margin-left: auto;
  transition: all 300ms ease;
  margin-right: auto;
  @media (max-width: 991px) {
    color: #1d2217 !important;
    border-bottom: 3px solid #cab996 !important;
  }
  @media screen and (max-width: 1060px) {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 40px;
    padding: 0 8px 12px 6px;
  }
`
const ServiceSection = ({ title, left, button, usp }) => {
  return (
    <ServiceSectionContainer left={left}>
      <ServiceSectionWrapper left={left}>
        <ServiceSectionTitle left={left}>{title}</ServiceSectionTitle>
        {usp.map((item, index) => {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <ServiceItem key={`service${index}`} {...item} left={left} />
        })}
        <div className="d-flex justify-content-center">
          {left ? (
            <Button
              label={button.title}
              shadowColor="#D8D8D8"
              to={button.url}
            />
          ) : (
            <Button
              type={1}
              label={button.title}
              shadowColor="#D8D8D8"
              to={button.url}
            />
          )}
        </div>
      </ServiceSectionWrapper>
    </ServiceSectionContainer>
  )
}
const ServiceItemContainer = styled.div`
  margin-bottom: 56px;
  @media screen and (max-width: 1060px) {
    margin-bottom: 44px;
  }
`
const ServiceItemHeading = styled.div`
  display: flex;
  align-items: center;

  h6 {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 0;
    margin-left: 20px;
    color: ${(props) => (props.left ? '#1D2217' : 'white')};
    @media (max-width: 991px) {
      color: #1d2217 !important;
    }
  }
  transition: all 300ms ease;
  @media screen and (max-width: 1060px) {
    img {
      margin-right: 10px;
      width: 20px;
      height: 24px;
      object-fit: contain;
      object-position: center;
    }
    h6 {
      font-size: 18px;
      line-height: 24px;
    }
  }
`
const ServiceItemDescription = styled(ParseContent)`
  text-align: left;
  color: ${(props) => (props.left ? '#1D2217' : 'white')};
  font-size: 16px;
  line-height: 30px;
  margin-left: 36px;
  transition: all 300ms ease;
  @media screen and (max-width: 1060px) {
    font-size: 13px;
    line-height: 20px;
    margin-left: 30px;
  }

  @media (max-width: 991px) {
    color: #1d2217 !important;
  }
`
const ServiceItem = ({ title, description, left }) => {
  return (
    <ServiceItemContainer>
      <ServiceItemHeading left={left}>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        {left ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="18"
            viewBox="0 0 15 18"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2 3L12 9L2 15V3Z"
              stroke="#1D2217"
              strokeWidth="3"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="16"
            viewBox="0 0 13 16"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2 3L10 8L2 13V3Z"
              stroke="#F1EBDA"
              strokeWidth="3"
            />
          </svg>
        )}
        <h6>{title}</h6>
      </ServiceItemHeading>
      <ServiceItemDescription left={left} content={description} />
    </ServiceItemContainer>
  )
}
